import { ChallengeServerlessData } from '../../../api/challenges/challenge.api';
import {
  ChallengeSection,
  ChallengeStep,
} from '@wix/ambassador-challenges-v1-challenge/types';

export interface IChallengeContext {
  isDescriptionShown: boolean;
  challengeData: ChallengeServerlessData;
  challengeSections?: ChallengeSection[];
  challengeSteps?: ChallengeStep[];
  requestChallengeSections?: Function;
  requestChallengeSteps?: Function;
}

export const defaultChallengeData = {
  challenge: null,
  badgesData: null,
  groupData: null,
  instructorsData: null,
};

export const challengeDefaultValue = {
  isDescriptionShown: false,
  challengeData: defaultChallengeData,
  challengeSections: null,
  challengeSteps: null,
  requestChallengeSections: null,
  requestChallengeSteps: null,
};

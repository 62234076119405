import { useControllerData } from '../main/controllerContext';
import { useIsFullWithInViewer } from '../../services/hooks/useIsFullWidthInViewer';

export interface IDefaultBIParams {
  bsi?: string;
  metaSiteId?: string;
  visitorId?: string;
  appId?: string;
  instanceId?: string;
  siteOwnerId?: string;
}
export interface IGeneralDataContext {
  isFullWidthLayout?: boolean;
  instance: string;
  instanceId: string;
  host?: any;
  msid: string;
  defaultBIParams: IDefaultBIParams;
  startTimeOfRender: number;
  regionalSettings: string;
  isMyWalletInstalled: boolean;
  isProfileInstalled: boolean;
  profileUrl: string;
}

export const useGeneralData = () => {
  const props = useControllerData();
  const isFullWidth = useIsFullWithInViewer();

  return {
    host: props.host,
    isFullWidthLayout: isFullWidth,
    instanceId: props.instanceId,
    instance: props.instance,
    msid: props.msid,
    defaultBIParams: props.defaultBIParams,
    startTimeOfRender: props.startTimeOfRender,
    regionalSettings: props.regionalSettings,
    isMyWalletInstalled: props.isMyWalletInstalled,
    isProfileInstalled: props.isProfileInstalled,
    profileUrl: props.profileUrl,
  };
};

import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IPaidPlansContext } from './interfaces';
import { WarmupData } from '../../../services/WarmupData';
import { isPaidPlansInstalled } from './helpers/isPaidPlansInstalled';
import { loadUserPaidPlans } from './api/loadPaidPlans';
import { userProviderPropsMap } from '../../User/userProviderPropsMap';
import { getUserEligiblePlans } from './helpers/getUserEligiblePlans';
import userTypeHandlers from '../../User/helpers/userTypeHandlers';
import handleUserLogin from './helpers/handleUserLogin';

let PAID_PLANS_DATA: IPaidPlansContext = null;

const WARMUP_DATA_KEY_LIST = 'paidPlansList';
const WARMUP_DATA_KEY_PROGRAMS = 'programsPaidPlans';

export const getPaidPlansData = async (
  flowAPI: ControllerFlowAPI,
): Promise<IPaidPlansContext> => {
  const userType = (await userProviderPropsMap(flowAPI)).userType;
  const isPPInstalled = await isPaidPlansInstalled(flowAPI);

  if (PAID_PLANS_DATA) {
    return PAID_PLANS_DATA;
  }

  if (
    userTypeHandlers.isJoinedAlreadyWithoutSuspended(userType) ||
    !isPPInstalled
  ) {
    PAID_PLANS_DATA = {
      userPaidPlans: [],
      eligiblePlans: [],
    };
  }

  try {
    const [userPaidPlans, eligiblePlans] = await Promise.all([
      loadUserPaidPlans(flowAPI.httpClient),
      getUserEligiblePlans(flowAPI),
    ]);

    PAID_PLANS_DATA = {
      userPaidPlans,
      eligiblePlans,
    };
  } catch (err) {
    console.error(err);
  }

  return PAID_PLANS_DATA;
};

export const getPaidPlansInitialData = async (
  flowAPI: ControllerFlowAPI,
): Promise<IPaidPlansContext> => {
  const warmupData = new WarmupData(flowAPI);
  const ssrData = warmupData.get(WARMUP_DATA_KEY_PROGRAMS);

  await handleUserLogin(flowAPI);

  if (ssrData) {
    return ssrData;
  }

  const paidPlans = await getPaidPlansData(flowAPI);

  warmupData.set(WARMUP_DATA_KEY_PROGRAMS, {
    userPaidPlans: PAID_PLANS_DATA.userPaidPlans,
    eligiblePlans: PAID_PLANS_DATA.eligiblePlans,
  });

  return paidPlans;
};

export const getPaidPlansInitialDataForList = async (
  flowAPI: ControllerFlowAPI,
): Promise<Pick<IPaidPlansContext, 'userPaidPlans'>> => {
  const warmupData = new WarmupData(flowAPI);
  const ssrData = warmupData.get(WARMUP_DATA_KEY_LIST);

  if (!(await isPaidPlansInstalled(flowAPI))) {
    return {
      userPaidPlans: [],
    };
  }

  const userPaidPlans =
    ssrData || (await loadUserPaidPlans(flowAPI.httpClient));

  warmupData.set(WARMUP_DATA_KEY_LIST, ssrData);

  return {
    userPaidPlans,
  };
};

export const resetPaidPlansCache = () => {
  PAID_PLANS_DATA = null;
};

import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getMockedChallenge } from '../../../main/getMockedChallenges';
import { request } from '../../../../services/request';
import { listSteps } from '@wix/ambassador-challenges-v1-challenge/http';
import {
  ChallengeStep,
  DescriptionFieldSet,
  DurationUnit,
} from '@wix/ambassador-challenges-v1-challenge/types';

export default async function requestChallengeSteps(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ChallengeStep[]> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (!slugOrChallengeId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }

  return (
    await request(
      flowAPI,
      listSteps({
        challengeId: slugOrChallengeId,
        fromOffset: {
          unit: DurationUnit.DAYS,
          value: 0,
        },
        toOffset: {
          unit: DurationUnit.DAYS,
          value: 1000,
        },
        descriptionFieldSet: DescriptionFieldSet.STANDARD,
      }),
    )
  )?.data?.steps;
}

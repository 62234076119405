import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Category } from '@wix/ambassador-achievements-categories-v3-category/types';
import challengeSettings, {
  CategoriesTabsChosenCategoryDefault,
} from '../../settingsParams';
import { useMemo } from 'react';
import {
  getOrderedItems,
  getShortId,
} from '../../../../services/getOrderedItems';

type CategoryTab = {
  title: string;
  value: string;
  id: string;
  isSelectable: boolean;
};

export function useSortedCategoryTabs(categories: Category[]): CategoryTab[] {
  const { t } = useTranslation();
  const settings = useSettings();
  const hiddenCategories = settings.get(challengeSettings.categoriesHidden);
  const categoriesOrder = settings.get(challengeSettings.categoriesOrder);

  return useMemo(() => {
    const unsortedCategoryTabs = [
      {
        title: t('categories-menu.all-programs'),
        value: t('categories-menu.all-programs'),
        id: CategoriesTabsChosenCategoryDefault,
        isSelectable: true,
      },
      ...categories?.map(({ id, label }) => ({
        id,
        title: label,
        value: label,
        isSelectable: true,
      })),
    ].filter((category) => {
      return !(
        hiddenCategories[getShortId(category.id)] &&
        hiddenCategories[getShortId(category.id)].hidden
      );
    });

    return getOrderedItems<CategoryTab, string>({
      items: unsortedCategoryTabs,
      order: categoriesOrder?.order,
      predicate(item, orderedItem) {
        return getShortId(item.id) === orderedItem;
      },
    });
  }, [categories, categoriesOrder, hiddenCategories, t]);
}

import {
  challenge,
  ChallengeServerlessData,
} from '../../../../api/challenges/challenge.api';
import {
  getMockedChallenge,
  isMockedChallenge,
} from '../../../main/getMockedChallenges';
import { request } from '../../../../services/request';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Referrer } from '../../../storage/referrer';
import { getFromMemoryStorage } from '../../../storage/storage';

export default async function requestChallenge(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
  referrer?: Referrer,
): Promise<ChallengeServerlessData> {
  if (!slugOrChallengeId) {
    return null;
  }

  if (isMockedChallenge(slugOrChallengeId, flowAPI)) {
    const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

    return { challenge: mockedChallenge, badgesData: { badges: [] } };
  }

  const serverlessResponse = await getFromMemoryStorage(
    flowAPI.controllerConfig,
    {
      key: `requestChallenge:${slugOrChallengeId}`,
      getter: async () => request(flowAPI, challenge(slugOrChallengeId)),
      referrer: referrer || Referrer.ALL,
    },
  );

  if (serverlessResponse?.data?.errorMessage) {
    console.error(serverlessResponse.data.errorMessage);
  }

  return serverlessResponse?.data;
}

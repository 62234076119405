import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getMockedChallenge } from '../../../main/getMockedChallenges';
import { request } from '../../../../services/request';
import { listSections } from '@wix/ambassador-challenges-v1-challenge/http';
import {
  ChallengeSection,
  DescriptionFieldSet,
} from '@wix/ambassador-challenges-v1-challenge/types';

export default async function requestChallengeSections(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ChallengeSection[]> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (!slugOrChallengeId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }

  return (
    await request(
      flowAPI,
      listSections({
        challengeId: slugOrChallengeId,
        descriptionFieldSet: DescriptionFieldSet.STANDARD,
      }),
    )
  )?.data?.sections;
}
